import logo from './logo.svg';
import Bground from './particles.js'
import Headline from './headline.js'
import Signup from './signup';
import './App.css';

import Particles from "react-tsparticles";

const App = () => {
  const particlesInit = (main) => {
    console.log(main);

  };

  const particlesLoaded = (container) => {
    console.log(container);
  };
  return (
<div>
<div>
  <Headline />
</div>
<div >
<Signup/>
</div>
<div>
  <Bground/>
</div>
</div>
  )
}
export default App;
