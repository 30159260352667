import React from 'react';

const Signup = () => {
    return (
<div className="signUpBox">
<a href='https://twitter.com/xchcontinuum' target={'blank'}>
<img className='twitter' src={require('./twitter_logo.png')} />
</a>
<a href='https://xchcontinuum.substack.com' target={'blank'}>
<img className='substack' src={require('./substack_logo.png')} />
</a>
</div>
    )
}
export default Signup;




